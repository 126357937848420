import { useStaticQuery, graphql } from "gatsby";

const usePageData = () => {
  const data = useStaticQuery(
    graphql`
    {
      elleSlide: allFile(
        filter: { relativeDirectory: { eq: "awards/elle competition slide" } }
      ) {
        nodes {
          childImageSharp {
            ...BettyFashionImages
          }
        }
      }
      telstraAwardsSlide: allFile(
        filter: { relativeDirectory: { eq: "awards/telstra awards slide" } }
      ) {
        nodes {
          childImageSharp {
            ...BettyFashionImages
          }
        }
      }
      data: allDataYaml(filter: { codePage: { eq: "vision-mission" } }) {
        edges {
          node {
            ...fragmentVisionMissionPage
          }
        }
      }
    }
  `);

  return {
    data: data.data.edges[0].node,
    elleSlide: data.elleSlide.nodes,
    telstraAwardsSlide: data.telstraAwardsSlide.nodes,
  }
}

export default usePageData;
