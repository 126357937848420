import React from "react"
// import cx from 'classnames';
// import Img from "components/Img"

const ColumnImage = ({ className, src, alt, children }) => {
  if (!src) return null

  return (
    <div className={`column-image ${className}`}>
      <div className="column-image--image">
        <img src={src} alt={alt} />
      </div>
      <div className="column-image--text">{children}</div>
    </div>
  )
}

ColumnImage.defaultProps = {
  className: "",
  src: undefined,
  text: "",
  alt: "",
}

export default ColumnImage
