import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/queryVisionMission';
import ViewVisionMission from 'components/ViewVisionMission';

const VisionPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewVisionMission pageData={pageData} />
    </Layout>
  )
}

export default VisionPage
