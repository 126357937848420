import React from "react"
import SEO from "components/seo"

import Heading from "components/Heading"
import Quote from "components/Quote"
import ColumnImage from "components/ColumnImage"
import { motion } from "framer-motion"
import { motionVariants, heroTextVariants } from "utils/motion.utils"
import Img from "components/Img"
import imgVisionImage1 from "images/pageVision--image-1.jpg"
import imgVisionImage2 from "images/pageVision--image-2.jpg"
import imgVisionImage3 from "images/pageVision--image-3.jpg"
import Interweave from "interweave"

const ViewVisionMission = ({pageData}) => {
  const {
    page,
    headingLeft,
    headingRight,
    visionPrimary,
    missionPrimary,
    coreValuesBlock,
    testimonial
  } = pageData.data;

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--vision--hero">
        <motion.div className="container hero-container">
          <div className="hero-column is-relative hero-column--left has-one-row">
            <motion.div
              variants={motionVariants}
              initial="hiddenFromLeft"
              animate={"fadeFromLeft"}
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroVisionMissionImage1"
                isFluid={true}
              />
            </motion.div>
            <div className="hero-text">
              <motion.div
                initial={"hidden"}
                animate={"visible"}
                variants={heroTextVariants}
                className="hero-text-animation"
              >
                <Heading className="is-heading is-mobile--medium">
                  <Interweave content={headingLeft} />
                </Heading>
              </motion.div>
            </div>
          </div>
          <div className="hero-column is-relative hero-column--right has-one-row">
            <motion.div
              variants={motionVariants}
              animate={"fadeFromRight"}
              initial="hiddenFromRight"
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroVisionMissionImage2"
                isFluid={true}
              />
            </motion.div>
            <div className="hero-text">
              <motion.div
                initial={"hidden"}
                animate={"visible"}
                variants={heroTextVariants}
                className="hero-text-animation"
              >
                <Heading className="is-heading is-mobile--medium text-core-values">
                  <Interweave content={headingRight} />
                </Heading>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="max-container wrapper--vision--primary">
        <div className="container max-1048 ">
          <div className="vision-primary--vision">
            <Heading>{visionPrimary.heading}</Heading>
            <p>
              {visionPrimary.content}
            </p>
          </div>
          <div className="vision-primary--mission">
            <Heading>{missionPrimary.heading}</Heading>
            <p>{missionPrimary.content}</p>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--vision--core-values">
        <div className="container max-1048">
          <Heading>{coreValuesBlock.heading}</Heading>
          <div className="columns vision-pillar-container">
            <div className="column is-full-mobile is-one-third-tablet">
              <ColumnImage src={imgVisionImage1} alt={coreValuesBlock.integrity}>
                {coreValuesBlock.integrity}
              </ColumnImage>
            </div>
            <div className="column is-full-mobile is-one-third-tablet">
              <ColumnImage src={imgVisionImage2} alt={coreValuesBlock.education}>
                {coreValuesBlock.education}
              </ColumnImage>
            </div>
            <div className="column is-full-mobile is-one-third-tablet">
              <ColumnImage src={imgVisionImage3} alt={coreValuesBlock.creation}>
                {coreValuesBlock.creation}
              </ColumnImage>
            </div>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--vision--quote bg-grey max-quote">
        <Quote>
          <Interweave content={testimonial} />
        </Quote>
      </div>
    </React.Fragment>
  )
}

export default ViewVisionMission
